import React from "react"

function Footer () {
    return(
        <section id="footer" className="bg-[#222] mt-[84px] w-full">
            <div className="xl:max-w-[1280px] lg:max-w-[1024px] m-auto px-4 flex md:flex-row flex-col justify-between xl:pt-[62px] lg:pt-[52px] md:pt-[42px] py-[40px] xl:pb-[110px] lg:pb-[90px] md:pb-[70px]">
                <div className="xl:w-[700px] lg:w-[700px] md:w-[560px]">
                    <a href="#">
                        <img src="./img/whiteLogo.svg" className=""/>
                    </a>
                    <p className="text-[14px] font-medium mt-[24px] md:mb-[44px] mb-[20 px] text-[#fff]">
                        ЗАО "Первый Микрофинансовый Банк" является участником фонда страхования сбережений физических лиц. Свидетельство выдано 20 сентября 2011 года.
                    </p>
                    <div className="flex justify-between">
                        <div className="flex flex-col">
                            <a href="tel:0909" className="text-[#da0011] xl:text-[24px] lg:text-[20px] md:text-[18px] text-[10px] font-medium">0909</a>
                            <span className="text-[#6b6b6b] text-[12px] font-medium">Звонки внутри сети <br/> Таджикистана</span>
                        </div>
                        <div className="flex flex-col border-x-2 border-[#6b6b6b] md:px-[28px] px-[10px] ">
                            <a href="tel:+992 771 11 01 11 " className="text-[#da0011] xl:text-[24px] lg:text-[20px] md:text-[18px] text-[10px] font-medium">+992 771 11 01 11 </a>
                            <span className="text-[#6b6b6b] text-[12px] font-medium" >Звонки вне сети <br/>Таджикистана</span>
                        </div>
                        <div className="flex flex-col md:ml-[0] ml-[4px]">
                            <a href="tel:+992 44 600 1520" className="text-[#da0011] xl:text-[24px] lg:text-[20px] md:text-[18px] text-[10px] font-medium">+992 44 600 1520</a>
                            <span className="text-[#6b6b6b] text-[12px] font-medium">Телефон доверия<br/> НБТ</span>
                        </div> 
                    </div>
                </div>
                <div className="flex md:flex-col justify-between">
                    <div>
                        <p className="text-[14px] font-medium mt-[24px] mb-[22px] text-[#fff]">Социальные сети</p>
                        <ul className="flex space-x-[33px]">
                            <li>
                                <a href="https://www.facebook.com/TheFirstMicroFinanceBankTajikistan" target="_blank">
                                    <img src="./img/facebook.svg"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/thefirstmicrofinancebank?igshid=MzRlODBiNWFlZA==" target="_blank">
                                    <img src="./img/instagram.svg"/>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src="./img/telegram.svg"/>
                                </a>
                            </li>
                        </ul>
                        <ul className="flex space-x-[33px] mt-[26px]">
                            <li>
                                <a href="#">
                                    <img src="./img/tviter.svg"/>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src="./img/lining.svg"/>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src="./img/youTube.svg"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p className="text-[14px] font-medium md:mt-[39px] mt-[24px] mb-[17px] text-[#fff]">Приложение</p>
                        <ul className="flex space-x-[3px] ">
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=tj.fmfb.mobile&hl=ru&gl=US" target="_blank">
                                    <img src="./img/googlePlayGray2.svg"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://itunes.apple.com/app/id1641458468" target="_blank">
                                    <img src="./img/appStoreGray.svg"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer