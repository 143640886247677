import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default function Test2() {
  const { t } = useTranslation();
  return (
    <div className="xl:max-w-[1280px] lg:max-w-[1024px] m-auto  px-4 lg:pb-[20px]">
      <Carousel infinite showDots arrows={false} responsive={responsive}>
        <div className="flex flex-col md:flex-row md:justify-between justify-around xl:h-[400px] lg:h-[320px]">
          <div className="sliderStyle xl:w-[540px] md:w-[400px] w-[353px]">
            <h3 className="titleStyleSlider text-[#DA0011] xl:text-[36px] lg:text-[30px] md:text-[26px] text-center md:text-left   md:leading-[39px] font-medium pb-[28px] uppercase md:ml-[0] ml-[50px]">
              Управляйте своими финансами с легкостью
            </h3>
            <p className="textStyleSlider md:text-[22px] text-[18px] font-normal bold leading-[21px] pb-[30px] text-center md:text-left md:ml-[0] ml-[40px]">
              <b> FMFB Mobile</b> - ваш надежный партнер для удобного и
              безопасного управления банковскими счетами
            </p>
            {/* <a className="bg-[#da0011] rounded-full px-[34px] py-[16px] text-[#fff] font-normal">Подробнее</a> */}
            <ul className="flex md:justify-start justify-end">
              <li>
                {/* <a href="https://play.google.com/store/apps/details?id=tj.fmfb.mobile&hl=ru&gl=US" target="_blank"><img src="./img/googlePlay2.svg"/></a> */}
                <a href="https://play.google.com/store/apps/details?id=tj.fmfb.mobile&hl=ru&gl=US">
                  <img src="./img/googlePlay2.svg" />
                </a>
              </li>
              <li>
                <a
                  href="https://itunes.apple.com/app/id1641458468"
                  target="_blank"
                >
                  <img src="./img/appStore.svg" />
                </a>
              </li>
            </ul>
          </div>
          <div className="contents">
            <img
              className="xl:w-[583px] lg:w-[400px] md:w-[300px] w-[280px] md:ml-[0] ml-[30px] mt-[10px] md:mt-[0px]"
              src="./img/mobile.svg"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row  md:justify-between justify-around xl:h-[400px] lg:h-[320px] ">
          <div className="sliderStyle xl:w-[540px] md:w-[400px] w-[353px]">
            <h3 className="titleStyleSlider text-[#DA0011] xl:text-[36px] lg:text-[30px] md:text-[26px] text-center md:text-left leading-[39px] font-medium pb-[28px] uppercase md:ml-[0] ml-[50px]">
              совершайте оплату более чем в 8000 мест{" "}
            </h3>
            <p className="textStyleSlider md:text-[22px] text-[18px] font-normal leading-[21px] md:pb-[71px] pb-[20px] text-center md:text-left md:ml-[0] ml-[40px]">
              <b>Преимущества</b> оплаты по QR-коду в розничных сетях и сфере
              услуг способствуют более удобном
            </p>
            {/* <a className="bg-[#da0011] rounded-full px-[34px] py-[16px] text-[#fff] font-normal">Подробнее</a> */}
          </div>
          <div className="contents ">
            <img
              className="xl:w-[270px] lg:w-[220px] md:w-[220px] w-[180px] md:mb-[20px] md:ml-[0] ml-[80px]"
              src="./img/shtrikh.svg"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row  md:justify-between justify-around xl:h-[400px] lg:h-[320px] ">
          <div className="sliderStyle xl:w-[540px] md:w-[400px] w-[353px]">
            <h3 className="titleStyleSlider text-[#DA0011] xl:text-[36px] lg:text-[30px] md:text-[26px] text-center md:text-left leading-[39px] font-medium pb-[28px] uppercase md:ml-[0] ml-[50px]">
              Удаленная идентификация с fmfb mobile
            </h3>
            <p className="textStyleSlider md:text-[22px] text-[18px] font-normal leading-[21px] md:pb-[71px] pb-[20px] text-center md:text-left md:ml-[0] ml-[40px]">
              <b>Нет необходимости</b> посещать офисы или банки для процедуры
              идентификации, что обеспечивает гибкость и мобильность
            </p>
            {/* <a className="bg-[#da0011] rounded-full px-[34px] py-[16px] text-[#fff] font-normal">Подробнее</a> */}
          </div>
          <div className="contents ">
            <img
              className="redProfileStyle xl:w-[400px] lg:w-[300px] md:w-[280px] w-[240px] md:ml-[0] ml-[70px]"
              src="./img/redProfile.svg"
            />
          </div>
        </div>
      </Carousel>
    </div>
  );
}
