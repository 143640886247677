import React from "react";
function Testimonial () {
    return(
        <div className="flex md:flex-row flex-col-reverse justify-between place-items-center lg:max-w-[1024px] xl:max-w-[1280px] m-auto  xl:h-[400px] bg-[#fff] rounded-[50px] md:pt-0 pt-[30px] md:mt-[0] mt-[20px] md:pr-[47px] drop-shadow-2xl">
            <div>
                <img src="./img/hend2.png" className="rounded-[50px] xl:w-[644px]"/>
            </div>
            <div className="md:w-[550px] xl:space-y-[32px] lg:space-y-[28px] md:space-y-[8px] md:px-[0] px-[16px]">
                <p className="xl:text-[21px] lg:text-[18px] md:text-[16px] font-normal leading-[25px] text-justify">
                    <b className="text-[#da0011] xl:text-[21px] lg:text-[18px] md:text-[16px]">FMFB Mobile</b> - это мобильное приложение, которое предоставляет вам мгновенный доступ к вашим банковским счетам, переводам и платежам.
                </p>
                <p className="xl:text-[21px] lg:text-[18px] md:text-[16px]  font-normal leading-[25px] text-justify">
                    Управляйте своими финансами где угодно и в любое время с удобным и надежным приложением.
                </p>
            </div>
        </div>
    )
}
export default Testimonial