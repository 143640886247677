import React from "react";

function Advanteg () {
    return(
        <section className="lg:max-w-[1024px] xl:max-w-[1280px] m-auto xl:mt-[144px] lg:mt-[80px] md:mt-[60px] mt-[50px] px-4">
            <div className="flex md:flex-row flex-col justify-around">
                <div className="md:w-[597px]">
                    <h4 className="text-[#da0011] font-medium xl:text-[36px] lg:text-[30px] md:text-[26px] text-[20px] uppercase mb-[56px]">ПРЕИМУЩЕСТВА</h4>
                    <ul className="xl:space-y-[44px] lg:space-y-[34px] md:space-y-[20px] space-y-[10px]">
                        <li className="flex place-items-center hover:text-[#da0011] text-[18px] cursor-pointer font-medium md:border-hidden border-b-[2px] md:pb-[0] pb-[6px]">
                            <img alt="sayf.png" className="absolute" src="./img/litleRomb.svg" />
                            <div className=" relative flex items-center">
                                <h4 className="z-0 text-[36px] pl-[14px] mr-[20px]">1</h4>
                                <p>Мгновенный доступ к счетам и операциям 24/7</p>
                            </div>
                        </li>
                        <li className="flex place-items-center hover:text-[#da0011] text-[18px] cursor-pointer font-medium md:border-hidden border-b-[2px] md:pb-[0] pb-[6px]">
                            <img alt="sayf.png" className="absolute" src="./img/litleRomb.svg" />
                            <div className=" relative flex items-center">
                                <h4 className="z-0 text-[36px] pl-[14px] mr-[20px]">2</h4>
                                <p>Просмотр истории операций и состояния баланса с легкостью</p>
                            </div>
                        </li>
                        <li className="flex place-items-center hover:text-[#da0011] text-[18px] cursor-pointer font-medium md:border-hidden border-b-[2px] md:pb-[0] pb-[6px]">
                            <img alt="sayf.png" className="absolute" src="./img/litleRomb.svg" />
                            <div className=" relative flex items-center">
                                <h4 className="z-0 text-[36px] pl-[14px] mr-[20px]">3</h4>
                                <p>Отправка и получение переводов в режиме реального времени</p>
                            </div>
                        </li>
                        <li className="flex place-items-center hover:text-[#da0011] text-[18px] cursor-pointer font-medium md:border-hidden border-b-[2px] md:pb-[0] pb-[6px]">
                            <img alt="sayf.png" className="absolute" src="./img/litleRomb.svg" />
                            <div className=" relative flex items-center">
                                <h4 className="z-0 text-[36px] pl-[12px] mr-[20px]">4</h4>
                                <p>Безопасное и защищенное использование со встроенными мерами безопасности</p>
                            </div>                          
                        </li>
                        <li className="flex place-items-center hover:text-[#da0011] text-[18px] cursor-pointer font-medium">
                            <img alt="sayf.png" className="absolute" src="./img/litleRomb.svg" />
                            <div className=" relative flex items-center">
                                <h4 className="z-0 text-[36px] pl-[14px] mr-[20px]">5</h4>
                                <p>Удобное управление платежами и счетами через интуитивно понятный интерфейс</p>
                            </div>                          
                        </li>
                    </ul>
                </div>
                <div>
                    <img className="phoneStyleAdvan md:ml-[0] md:mt-[0] mt-[25px] ml-[70px] " src="./img/phone.svg"/>
                </div>
            </div>
        </section>
    )
}

export default Advanteg