import React from "react";

function IcbMobile() {
  return (
    <section className="bg-[#f5f5f5] mt-[109px]">
      <div className="flex md:flex-row  flex-col lg:max-w-[1024px] xl:max-w-[1280px] m-auto  px-4 place-items-center">
        <div iv className="md:py-[30px] ">
          <h4 className="text-[#da0011] font-medium lg:text-[30px] md:text-[26px] text-[20px] uppercase md:pt-[0] pt-[20px] ">
            Управляйте своими финансамис FMFB Mobile
          </h4>
          <p className="text-[21px] font-normal leading-[25px] text-justify xl:my-[28px] lg:my-[24px] md:my-[20px] my-[10px]">
            Скачайте приложение прямо сейчас и начните экономить время и силы
            при управлении вашими финансами
          </p>
          <ul className="flex">
            <li>
              {/* <a href="https://play.google.com/store/apps/details?id=tj.fmfb.mobile&hl=ru&gl=US" target="_blank"><img src="./img/googlePlay2.svg"/></a> */}
              <a href="https://play.google.com/store/apps/details?id=tj.fmfb.mobile&hl=ru&gl=US">
                <img src="./img/googlePlay2.svg" />
              </a>
            </li>
            <li>
              <a
                href="https://itunes.apple.com/app/id1641458468"
                target="_blank"
              >
                <img src="./img/appStore.svg" />
              </a>
            </li>
          </ul>
        </div>
        <img
          className="xl:w-[821px] lg:w-[500px] md:w-[430px]"
          src="./img/bannerMob.svg"
        />
      </div>
    </section>
  );
}

export default IcbMobile;
