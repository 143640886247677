import React from "react";

function Documentation() {
  return (
    <section
      id="doc"
      className="xl:max-w-[1280px] lg:max-w-[1024px] m-auto px-4"
    >
      <h4 className="text-[#da0011] font-medium xl:text-[36px] lg:text-[30px] md:text-[26px] text-[20px] uppercase xl:mt-[80px] lg:mt-[60px] md:mt-[40px] mt-[50px] md:mb-[66px] mb-[30px]">
        документация
      </h4>
      <div className="flex md:flex-row flex-col justify-between">
        <ul className="xl:w-[600px] lg:w-[480px] md:w-[360px] space-y-[20px]">
          <li className=" md:border-hidden border-b-[2px] md:pb-[0] pb-[16px]">
            <a
              href="./doc/Privacy_policy_FMFB_mobi.html"
              target="_blank"
              className="flex  text-[14px] hover:text-[#da0011] font-medium leading-[25px] text-justify items-start"
            >
              <img
                src="./img/file.svg"
                className="w-[20px] h-[25px] mr-[6px]"
              />
              FMFB MOBILE - Политика конфиденциальности мобильного приложения
              «FMFB mobile»
            </a>
          </li>
          <li className=" md:border-hidden border-b-[2px] md:pb-[0] pb-[16px]">
            <a
              href="./doc/FMFB_mobi_License_Agreement.html"
              target="_blank"
              className="flex  hover:text-[#da0011] text-[14px] font-medium leading-[25px] text-justify items-start"
            >
              <img
                src="./img/file.svg"
                className="w-[20px] h-[25px] mr-[6px]"
              />
              FMFB MOBILE - Лицензионное соглашение на использование программы
              «FMFB mobile» для мобильных устройств
            </a>
          </li>
          <li className=" md:border-hidden border-b-[2px] md:pb-[0] pb-[16px]">
            <a
              href="./doc/Public_offer_FMFB_mobi.html"
              target="_blank"
              className="flex hover:text-[#da0011] text-[14px] font-medium leading-[25px] text-justify items-start"
            >
              <img
                src="./img/file.svg"
                className="w-[20px] h-[25px] mr-[6px]"
              />
              FMFB MOBILE - ПУБЛИЧНАЯ ОФЕРТА
            </a>
          </li>
          {/* <li className=" md:border-hidden border-b-[2px] md:pb-[0] pb-[16px]">
            <a
              href="./doc/app-release.apk"
              target="_blank"
              className="flex hover:text-[#da0011] text-[14px] font-medium leading-[25px] text-justify items-start"
            >
              <img
                src="./img/file.svg"
                className="w-[20px] h-[25px] mr-[6px]"
              />
              APK
            </a>
          </li> */}
        </ul>
        {/* <ul className="xl:w-[600px] lg:w-[480px] md:w-[360px] space-y-[20px] md:pb-[0] pb-[6px] md:mt-[0] mt-[16px]">
                        <li className=" md:border-hidden border-b-[2px] md:pb-[0] pb-[16px] ">
                            <a href="#" target="_blank" className="flex hover:text-[#da0011] text-[14px] font-medium leading-[25px] text-justify items-start">
                                <img src="./img/file.svg" className="w-[20px] h-[25px] mr-[6px]"/>
                                FMFB MOBILE - это мобильное приложение, которое предоставляет вам мгновенный доступ к вашим банковским счетам, переводам и платежам.
                            </a>
                        </li>
                        <li className=" md:border-hidden border-b-[2px] md:pb-[0] pb-[16px]">
                            <a href="#" target="_blank" className="flex hover:text-[#da0011] text-[14px] font-medium leading-[25px] text-justify items-start">
                                <img src="./img/file.svg" className="w-[20px] h-[25px] mr-[6px]"/>
                                FMFB MOBILE - это мобильное приложение, которое предоставляет вам мгновенный доступ к вашим банковским счетам, переводам и платежам.
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank" className="flex hover:text-[#da0011] text-[14px] font-medium leading-[25px] text-justify items-start">
                                <img src="./img/file.svg" className="w-[20px] h-[25px] mr-[6px]"/>
                                FMFB MOBILE - это мобильное приложение, которое предоставляет вам мгновенный доступ к вашим банковским счетам, переводам и платежам.
                            </a>
                        </li>
                    </ul> */}
      </div>
    </section>
  );
}

export default Documentation;
