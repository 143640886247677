import React from "react";

function Functionality () {
    return (
        <section id="functionality" className="xl:max-w-[1280px] lg:max-w-[1024px] m-auto px-4 xl:mt-[144px] lg:mt-[80px] md:mt-[60px] mt-[50px]">
            <h4 className="text-[#da0011] font-medium xl:text-[36px] lg:text-[30px] text-[20px] md:text-[26px] uppercase ">Функциональность и возможности</h4>
            <div className="flex md:flex-wrap flex-wrap justify-between mt-[70px] ">
                <div className="bgBorderStile flex justify-center items-center border border-[#F5F5F5] bg-[#F5F5F5] rounded-[10px] p-[40px] hover:scale-110 transition delay-100">
                    <img alt="rombBg.png" className="rombStyle absolute" src="./img/romb.svg"/>
                    <div className=" relative items-end">
                        <img alt="sayf.png" src="./img/seyf.svg" />
                        <h4 className="text-[18px] font-bold text-center">Управление <br/> счетами</h4>
                    </div>
                </div>
                <div className="bgBorderStile flex justify-center items-center border border-[#F5F5F5] bg-[#F5F5F5] rounded-[10px] p-[45px] p-[45px] hover:scale-110 transition delay-100">
                    <img alt="rombBg.png" className="rombStyle absolute" src="./img/romb.svg"/>
                    <div className=" relative items-end">
                        <img alt="calendar.png" src="./img/calendar.svg" />
                        <h4 className="text-[18px] font-bold text-center">Статистика <br/> платежей</h4>
                    </div>
                </div> 
                <div className="bgBorderStile flex justify-center items-center border border-[#F5F5F5] bg-[#F5F5F5] rounded-[10px] p-[40px] md:mt-[0] mt-[20px] hover:scale-110 transition delay-100">
                    <img alt="rombBg.png" className="rombStyle absolute" src="./img/romb.svg"/>
                    <div className=" relative items-end">
                        <img alt="monet.png" src="./img/moneti.svg" />
                        <h4 className="text-[18px] font-bold text-center">Оплата <br/>счетов</h4>
                    </div>
                </div>
                <div className="bgBorderStile flex justify-center items-center border border-[#F5F5F5] bg-[#F5F5F5] rounded-[10px]  p-[50px] mt-[20px] md:mt-[16px] lg:[mt-0] xl:mt-[0] hover:scale-110 transition delay-100">
                    <img alt="rombBg.png" className="rombStyle absolute" src="./img/romb.svg"/>
                    <div className=" relative items-end">
                        <img alt="zomok.png" src="./img/zomok.svg" />
                        <h4 className="text-[18px] font-bold text-center">SWIFT <br/> переводы</h4>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Functionality