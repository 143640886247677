import React, { useState } from 'react';

import Selider from './component/Selider';
import Testimonial from './component/Testimonial';
import Functionality from './component/Functionality';
import Advanteg from './component/Advanteg';
import IcbMobile from './component/IcbMobile';
import Documentation from './component/Documentation';
import Footer from './component/Footer';
import i18n from './i18n';
import LocaleContext from './LocaleContext';



export default function App() {

  const [locale, setLocale] = useState(i18n.language);

  i18n.on('languageChanged', (lng) => setLocale(i18n.language));

  const handleChange = (event)=>{
    i18n.changeLanguage(event.target.value);
  }

  return (
    <div className='bg-[#F5F5F5] h-[680px] w-full '>
      <LocaleContext.Provider value={{locale, setLocale}}>
        <div id="header" className="flex justify-between lg:max-w-[1024px] xl:max-w-[1280px] m-auto py-[52px] place-items-center px-4">
            <div>
                <img className="w-[491px] h-[47px]" src="./img/blackLogo.svg"/>
            </div>
            <div>
              <ul className="md:block hidden"> 
                <li className="flex place-items-center text-[#5E5E5E] font-[400px]"><img className="w-[25px] h-[16px] mr-[6px] " src="./img/ru.svg" />Русский</li>
              </ul>
              <img className="w-[25px] h-[16px] mr-[6px] md:hidden block ml-[10px]" src="./img/ru.svg" />
                {/* <select className="bg-[#f5f5f5]" value={locale} onChange={handleChange} id="lang">
                    <option  value="ru" id="ruFlag">Русский</option>
                    <option  value="en" id="enFlag">English</option>
                    <option  value="tj" id="tjFlag">Tajik</option>
                </select> */}
            </div>
        </div>
        <Selider/>
        <Testimonial/>
        <Functionality/>
        <Advanteg/>
        <IcbMobile/>
        <Documentation/>
        <Footer/>
      </LocaleContext.Provider>

    </div>
  )
}